import React from 'react';

export const FeedbackLogoThumbsDown: React.FC = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 31C3.5 15.8122 15.8122 3.5 31 3.5C46.1878 3.5 58.5 15.8122 58.5 31C58.5 46.1878 46.1878 58.5 31 58.5C15.8122 58.5 3.5 46.1878 3.5 31Z"
        fill="#FEE2E2"
      />
      <path
        d="M36.416 33.1665V20.1665"
        stroke="#991B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7507 37.6298L28.8341 33.1665H22.5182C22.1819 33.1665 21.8501 33.0882 21.5493 32.9378C21.2484 32.7873 20.9867 32.5689 20.7849 32.2998C20.5831 32.0307 20.4467 31.7184 20.3865 31.3874C20.3263 31.0565 20.344 30.7161 20.4382 30.3932L22.9624 21.7265C23.0937 21.2765 23.3674 20.8811 23.7424 20.5998C24.1174 20.3186 24.5736 20.1665 25.0424 20.1665H39.6674C40.242 20.1665 40.7931 20.3948 41.1995 20.8011C41.6058 21.2074 41.8341 21.7585 41.8341 22.3332V30.9998C41.8341 31.5745 41.6058 32.1256 41.1995 32.5319C40.7931 32.9382 40.242 33.1665 39.6674 33.1665H36.6774C36.2743 33.1667 35.8793 33.2794 35.5367 33.4918C35.1941 33.7042 34.9176 34.008 34.7382 34.369L31.0007 41.8332C30.4899 41.8268 29.987 41.7052 29.5298 41.4772C29.0725 41.2492 28.6727 40.9209 28.3602 40.5167C28.0477 40.1125 27.8306 39.6429 27.725 39.143C27.6195 38.6431 27.6283 38.1259 27.7507 37.6298Z"
        stroke="#854D0E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 31C3.5 15.8122 15.8122 3.5 31 3.5C46.1878 3.5 58.5 15.8122 58.5 31C58.5 46.1878 46.1878 58.5 31 58.5C15.8122 58.5 3.5 46.1878 3.5 31Z"
        stroke="#FEF2F2"
        strokeWidth="7"
      />
    </svg>
  );
};
