import React from 'react';

export const FeedbackLogoThumbsUp: React.FC = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.5" y="3.5" width="55" height="55" rx="27.5" fill="#CCFBF1" />
      <rect
        x="3.5"
        y="3.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="#F0FDFA"
        strokeWidth="7"
      />
      <path
        d="M25.418 28.667V41.667"
        stroke="#14B8A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0833 24.2033L33 28.6667H39.3158C39.6522 28.6667 39.9839 28.745 40.2848 28.8954C40.5856 29.0458 40.8473 29.2642 41.0492 29.5333C41.251 29.8024 41.3874 30.1148 41.4476 30.4457C41.5077 30.7767 41.49 31.1171 41.3958 31.44L38.8717 40.1067C38.7404 40.5567 38.4667 40.9521 38.0917 41.2333C37.7166 41.5146 37.2605 41.6667 36.7917 41.6667H22.1667C21.592 41.6667 21.0409 41.4384 20.6346 41.0321C20.2283 40.6257 20 40.0746 20 39.5V30.8333C20 30.2587 20.2283 29.7076 20.6346 29.3013C21.0409 28.8949 21.592 28.6667 22.1667 28.6667H25.1567C25.5598 28.6665 25.9548 28.5538 26.2974 28.3414C26.6399 28.1289 26.9165 27.8251 27.0958 27.4642L30.8333 20C31.3442 20.0063 31.8471 20.128 32.3043 20.356C32.7615 20.5839 33.1613 20.9123 33.4739 21.3165C33.7864 21.7207 34.0035 22.1902 34.109 22.6901C34.2146 23.19 34.2058 23.7073 34.0833 24.2033Z"
        stroke="#14B8A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
