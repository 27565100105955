import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const waitlistContract = c.router(
  {
    addToWaitlist: {
      method: 'POST',
      path: '/add',
      body: z.object({
        email: z.string().email(),
      }),
      responses: {
        201: z.object({}),
      },
    },
  },
  { pathPrefix: '/waitlist' },
);
