export interface TreeNode<T> {
  children?: TreeNode<T>[];
  data: T;
}

export function buildTree<T extends { path: string }>(
  nodes: T[],
): TreeNode<T>[] {
  const tree: TreeNode<T>[] = [];
  const nodeMap: { [key: string]: TreeNode<T> } = {};

  nodes.forEach((node) => {
    const treeNode: TreeNode<T> = { data: node, children: [] };
    nodeMap[node.path] = treeNode;

    // eslint-disable-next-line lodash/prefer-includes
    if (node.path.indexOf('.') === -1) {
      tree.push(treeNode);
    } else {
      const parentPath = node.path.substring(0, node.path.lastIndexOf('.'));
      const parentNode = nodeMap[parentPath];

      if (parentNode && parentNode.children) {
        parentNode.children.push(treeNode);
      }
    }
  });

  return tree;
}
